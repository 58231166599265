import * as React from "react";

function SvgIcOrganisations(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <g transform="translate(1.000000, 0.000000)" fill="currentColor">
          <path
            d="M23,8 L23,24 L-1,24 L-1,11 L10,11 L10,22 L12,22 L12,8 L23,8 Z M21,10 L14,10 L14,22 L21,22 L21,10 Z M8,13 L1,13 L1,22 L8,22 L8,13 Z M19,18 L19,20 L16,20 L16,18 L19,18 Z M6,18 L6,20 L3,20 L3,18 L6,18 Z M19,15 L19,17 L16,17 L16,15 L19,15 Z M6,15 L6,17 L3,17 L3,15 L6,15 Z M19,12 L19,14 L16,14 L16,12 L19,12 Z M18,0 L18,7 L16,7 L16,2 L6,2 L6,10 L4,10 L4,0 L18,0 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SvgIcOrganisations;
