import * as React from "react";

function SvgIcList(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M4.93 18.59a2.43 2.43 0 01-.714 1.75c-.476.492-1.06.73-1.75.715-.692-.016-1.276-.254-1.752-.715C.238 19.88 0 19.296 0 18.59c0-.707.238-1.29.714-1.752.476-.46 1.06-.698 1.751-.714a2.268 2.268 0 011.751.714 2.43 2.43 0 01.715 1.751zm0-6.59c0 .691-.238 1.275-.714 1.751s-1.06.714-1.75.714c-.692 0-1.276-.238-1.752-.714S0 12.691 0 12c0-.691.238-1.275.714-1.751s1.06-.714 1.751-.714c.691 0 1.275.238 1.751.714s.715 1.06.715 1.751zm18.11 5.345v2.466a.459.459 0 01-.115.3.316.316 0 01-.3.114H7.005a.405.405 0 01-.3-.115.405.405 0 01-.116-.3v-2.465c0-.107.039-.2.116-.276a.497.497 0 01.3-.138h15.62c.108 0 .208.046.3.138.092.092.13.184.115.276zM4.93 5.411c0 .69-.238 1.274-.714 1.75-.******************.75.715-.692 0-1.276-.238-1.752-.714C.238 6.685 0 6.102 0 5.41s.238-1.275.714-1.751c.476-.477 1.06-.715 1.751-.715.691 0 1.275.238 1.751.715.476.476.715 1.06.715 1.75zm18.11 5.345v2.488c0 .108-.038.2-.115.277a.405.405 0 01-.3.115H7.005a.405.405 0 01-.3-.115.377.377 0 01-.116-.277v-2.488c0-.108.039-.2.116-.277a.405.405 0 01.3-.115h15.62c.108 0 .208.039.3.115.092.077.13.17.115.277zm0-6.567v2.466c0 .107-.038.2-.115.276a.497.497 0 01-.3.138H7.005a.372.372 0 01-.3-.138.433.433 0 01-.116-.276V4.189a.41.41 0 01.116-.3.405.405 0 01.3-.114h15.62c.108 0 .208.038.3.115.092.077.13.177.115.3z"
        />
      </g>
    </svg>
  );
}

export default SvgIcList;
