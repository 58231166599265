import { FixedColorField } from "@/configurator/config-types";

export const DEFAULT_FIXED_COLOR_FIELD: FixedColorField = {
  type: "fixed",
  value: "#1f77b4",
  opacity: 80,
};

export const DEFAULT_COLOR = [222, 222, 222, 125];

export const FLY_TO_DURATION = 500;

export const RESET_DURATION = 1500;
