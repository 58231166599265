import * as React from "react";

function SvgIcSettings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M9.824 16.067l-1.624.361a3.797 3.797 0 00-.608-.62l.389-1.617-1.379-.586-.892 1.403A3.758 3.758 0 004.842 15l-.868-1.418-1.389.561.361 1.624c-.232.18-.439.384-.619.608L.71 15.986l-.585 1.379 1.403.893a3.704 3.704 0 00-.007.868l-*********************.624-.361c.18.232.385.439.608.62l-.389 1.617 1.379.586.892-1.403c.285.036.576.039.868.008l.868 1.418 1.389-.561-.361-1.624c.232-.18.439-.385.62-.608l1.617.389.585-1.379-1.403-.893c.036-.285.039-.577.007-.868l1.419-.868-.562-1.389h-.001zm-4.02 4.047a1.498 1.498 0 11-1.122-2.778 1.498 1.498 0 011.122 2.778zM22.5 8.25l1.328-.996a8.782 8.782 0 00-.311-1.145L21.871 5.9l-.75-1.299.649-1.519a9.155 9.155 0 00-.841-.842l-1.528.64-1.299-.75-.198-1.643a8.904 8.904 0 00-1.145-.313L15.75 1.499h-1.5L13.254.171a8.94 8.94 0 00-1.145.31L11.9 2.127l-1.299.75-1.519-.649c-.299.262-.58.543-.842.841l.64 1.528-.75 1.299-1.642.198a8.774 8.774 0 00-.313 1.144L7.5 8.247v1.5l-1.327.996c.077.392.185.773.311 1.145l1.646.209.75 1.299-.649 1.519c.262.299.543.581.841.842l1.528-.64 1.299.75.198 1.643c.372.127.752.235 1.144.313l1.009-1.325h1.5l.996 1.327a8.94 8.94 0 001.145-.31l.209-1.646 1.299-.75 1.519.649c.299-.262.581-.543.842-.841l-.64-1.528.75-1.299 1.643-.198c.127-.372.235-.752.313-1.144l-1.325-1.009v-1.5l-.001.001zM15 12.375a3.375 3.375 0 110-6.75 3.375 3.375 0 010 6.75z"
        />
      </g>
    </svg>
  );
}

export default SvgIcSettings;
