import * as React from "react";

function SvgIcTwitter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M22.689 5.472a9.231 9.231 0 01-2.16 2.232v.552c0 1.168-.168 2.328-.504 3.48a12.793 12.793 0 01-1.536 3.336 12.224 12.224 0 01-2.472 2.808c-.96.8-2.112 1.456-3.456 1.968-1.344.512-2.792.752-4.344.72-2.416 0-4.624-.648-6.624-1.944.304.048.648.072 1.032.072 2.016 0 3.808-.616 5.376-1.848a4.39 4.39 0 01-2.52-.864 4.226 4.226 0 01-1.536-2.136c.304.032.576.048.816.048.384 0 .768-.048 1.152-.144a4.297 4.297 0 01-2.496-1.488c-.656-.784-.984-1.704-.984-2.76v-.048a4.43 4.43 0 001.968.552 4.493 4.493 0 01-1.416-1.536 4.128 4.128 0 01-.528-2.064c0-.784.2-1.512.6-2.184a12.495 12.495 0 003.936 3.192c1.536.8 3.2 1.24 4.992 1.32a4.24 4.24 0 01-.12-.984c0-1.2.424-2.216 1.272-3.048.848-.832 1.864-1.256 3.048-1.272 1.264 0 2.32.456 3.168 1.368a8.384 8.384 0 002.736-1.056c-.32 1.024-.952 1.824-1.896 2.4a9.053 9.053 0 002.496-.672z"
        />
      </g>
    </svg>
  );
}

export default SvgIcTwitter;
